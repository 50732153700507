/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const villageRouter = {
  path: '',
  component: Layout,
  children: [
    {
      path: 'travel/jingqu',
      meta: {
        title: "景区"
      },
      component: () =>
        import('@/views/travel/jingqu/jingquList.vue')
    },
    {
      path: 'travel/store',
      meta: {
        title: "便民商家"
      },
      component: () =>
        import('@/views/travel/store/storeList.vue')
    },
    {
      path: 'travel/jingqu/jqXiangQIng/:Id',
      meta: {
        title: "详情"
      },
      component: () =>
        import('@/views/travel/jingqu/jqXiangQIng.vue')
    },
    {
      path: 'travel/jingqu/jqJieShao/jqXiangQIng/:Id',
      meta: {
        title: "景区介绍"
      },
      component: () =>
        import('@/views/travel/jingqu/jqJieShao.vue')
    },
    {
      path: 'travel/jingqu/jqJieShao/attractions/:Id',
      meta: {
        title: "主要景点"
      },
      component: () =>
        import('@/views/travel/jingqu/attractions.vue')
    },
    {
      path: 'travel/jingqu/jqXiangQIng/hotel/:Id',
      meta: {
        title: "酒店住宿"
      },
      component: () =>
        import('@/views/travel/jingqu/hotel.vue')
    },
    {
      path: 'travel/jingqu/jqXiangQIng/hotel/hotelXq/:Id',
      meta: {
        title: "酒店详情"
      },
      component: () =>
        import('@/views/travel/jingqu/hotelXq.vue')
    },
    {
      path: 'travel/jingqu/jqXiangQIng/jdMore/:Id',
      meta: {
        title: "更多景点"
      },
      component: () =>
        import('@/views/travel/jingqu/jdMore.vue')
    },
    {
      path: 'travel/deliciousFood',
      meta: {
        title: "特色美食"
      },
      component: () =>
        import('@/views/travel/deliciousFood/foodList.vue')
    },
    {
      path: 'travel/deliciousFood/foodXq/:Id',
      meta: {
        title: "详情"
      },
      component: () =>
        import('@/views/travel/deliciousFood/foodXq.vue')
    },
    {
      path: 'travel/specialty',
      meta: {
        title: "农家特产"
      },
      component: () =>
        import('@/views/travel/specialty/specialtyList.vue')
    },
    {
      path: 'travel/outdoorLife',
      meta: {
        title: "户外生活"
      },
      component: () =>
        import('@/views/travel/outdoorLife/lifeList.vue')
    },
    {
      path: 'travel/pick',
      meta: {
        title: "采摘园"
      },
      component: () =>
        import('@/views/travel/outdoorLife/pick.vue')
    },
    {
      path: 'travel/gonglue',
      meta: {
        title: "旅游攻略"
      },
      component: () =>
        import('@/views/travel/gonglue/glList.vue')
    },
    {
      path: 'travel/gonglue/glXiangQing/:Id',
      meta: {
        title: "攻略详情"
      },
      component: () =>
        import('@/views/travel/gonglue/glXiangQing.vue')
    },
  ]
}

export default villageRouter