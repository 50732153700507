import Layout from '@/layout'

const villageRouter = {
  path: '/register',
  component: Layout,
  children: [{
    path: '/',
    name: 'register',
    meta: {
      title: "疫情防控"
    },
    component: () =>
      import('@/views/fangyi/register.vue')
  }, {
    path: '/register/luo',
    name: 'luo',
    meta: {
      title: "返淇人员报备"
    },
    component: () =>
      import('@/views/fangyi/luo.vue')
  }, {
    path: '/register/chuqi',
    name: 'chuqi',
    meta: {
      title: "出淇人员报备"
    },
    component: () =>
      import('@/views/fangyi/chuqi.vue')
  }, {
    path: '/register/geli',
    name: 'geli',
    meta: {
      title: "管控人员报备"
    },
    component: () =>
      import('@/views/fangyi/geli.vue')
  }, {
    path: '/register/fangyiType',
    name: 'fangyiType',
    meta: {
      title: "防疫措施"
    },
    component: () =>
      import('@/views/fangyi/fangyiType.vue')
  }, {
    path: '/register/yimiao',
    name: 'yimiao',
    meta: {
      title: "疫苗接种排查"
    },
    component: () =>
      import('@/views/fangyi/yimiao.vue')
  }, {
    path: '/register/newAdd',
    name: 'newAdd',
    meta: {
      title: "居民信息登记"
    },
    component: () =>
      import('@/views/fangyi/newAdd.vue')
  },  {
    path: '/register/changsuoAdd/:seId/:ekind/:title',
    name: 'changsuoAdd',
    meta: {
      title: "出入场所登记"
    },
    component: () =>
      import('@/views/fangyi/changsuoAdd.vue')
  },
  {
    path: '/fangyi/myGeli/mygeli',
    name: 'mygeli',
    meta: {
      title: "已上报隔离信息"
    },
    component: () =>
      import('@/views/fangyi/myGeli/mygeli.vue')
  },
  {
    path: '/register/fangyi/fanqiNew/fqShouye',
    name: 'fqShouye',
    meta: {
      title: "返(来)淇人员报备"
    },
    component: () =>
      import('@/views/fangyi/fanqiNew/fqShouye.vue')
  },
  {
    path: '/register/fangyi/fanqiNew/fqShouye/fqYou/:hid',
    name: 'fqYou',
    meta: {
      title: "返(来)淇人员报备"
    },
    component: () =>
      import('@/views/fangyi/fanqiNew/fqYou.vue')
  },
  {
    path: '/register/fangyi/fanqiNew/fqShouye/fqWu/:name/:idcard',
    name: 'fqWu',
    meta: {
      title: "返(来)淇人员报备"
    },
    component: () =>
      import('@/views/fangyi/fanqiNew/fqWu.vue')
  },
  {
    path: '/register/fangyi/chuqiNew/cqShouye',
    name: 'cqShouye',
    meta: {
      title: "在外人员登记"
    },
    component: () =>
      import('@/views/fangyi/chuqiNew/cqShouye.vue')
  },
  {
    path: '/register/fangyi/chuqiNew/cqShouye/cqYou/:hid',
    name: 'cqYou',
    meta: {
      title: "在外人员登记"
    },
    component: () =>
      import('@/views/fangyi/chuqiNew/cqYou.vue')
  },
  {
    path: '/register/fangyi/chuqiNew/cqShouye/cqWu/:name/:idcard',
    name: 'cqWu',
    meta: {
      title: "在外人员登记"
    },
    component: () =>
      import('@/views/fangyi/chuqiNew/cqWu.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye',
    name: 'ymShouye',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymShouye.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye/ymYou/:hid',
    name: 'ymYou',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymYou.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye/ymWu/:name/:idcard',
    name: 'ymWu',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymWu.vue')
  },


  ]
}

export default villageRouter