import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

import villageRouter from './modules/village'
import fangyi from './modules/fangyi'
import partymember from './modules/partymember'
/* Layout */
import Layout from '@/layout'

const router = new Router({
  routes: [{
    path: '/',
    component: Layout,
    children: [{
      path: '',
      name: 'Home',
      meta: {
        title: "掌上服务"
      },
      component: () =>
        import('@/views/home/index.vue')
    },]
  },
  {
    path: '/test',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "测试list"
      },
      component: () =>
        import('@/views/list.vue')
    },]
  },
  // 资讯中心
  {
    path: '/article',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "资讯中心"
      },
      component: () =>
        import('@/views/article/index.vue')
    },
    {
      path: 'detail/:Id',
      meta: {
        title: "资讯中心"
      },
      component: () =>
        import('@/views/article/detail.vue')
    },
    {
      path: '/travel',
      meta: {
        title: "乡村旅游"
      },
      component: () =>
        import('@/views/travel/index.vue')
    },
    ]
  },
    villageRouter, fangyi, partymember
  ]
});

// 动画效果
router.beforeEach((to, from, next) => {
  console.log(to.path)
  console.log(from.path)

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.path == from.path || ((to.path == '/' || to.path == '/serve' || to.path == '/center') && (from.path == '/' || from.path == '/serve' || from.path == '/login' || from.path == '/village'))) {
    store.commit('UPDATE_DIRECTION', "none")
  } else if (to.path.indexOf(from.path) != -1 || (from.name && to.name.indexOf(from.name) != -1)) {
    store.commit('UPDATE_DIRECTION', "forward")
  } else {
    store.commit('UPDATE_DIRECTION', "reverse")
  }
  next();
})

export default router